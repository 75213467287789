import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часто задаваемые вопросы в Povix Cleaning Co.
			</title>
			<meta name={"description"} content={"Уточнение чистоты - ваши проблемы, решения Povix"} />
			<meta property={"og:title"} content={"FAQ | Часто задаваемые вопросы в Povix Cleaning Co."} />
			<meta property={"og:description"} content={"Уточнение чистоты - ваши проблемы, решения Povix"} />
			<meta property={"og:image"} content={"https://povixvoom.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://povixvoom.com/img/3965825.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://povixvoom.com/img/3965825.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://povixvoom.com/img/3965825.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://povixvoom.com/img/3965825.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://povixvoom.com/img/3965825.png"} />
			<meta name={"msapplication-TileImage"} content={"https://povixvoom.com/img/3965825.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text font="--headline1" margin="0 0 8px 0">
							FAQ
						</Text>
						<Text as="p" font="--lead" margin="0" color="--greyD2">
							Готовитесь к "Свежему старту" в своем помещении? Мы подготовили ответы на некоторые часто задаваемые вопросы, чтобы сделать вашу уборку беспроблемной и приятной.
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Какие услуги вы предлагаете?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Povix специализируется на комплексной уборке квартир, таунхаусов, коттеджей и офисов. Мы предлагаем широкий спектр услуг - от глубокой очистки до текущего обслуживания, благодаря чему каждое помещение, к которому мы прикасаемся, превращается в райский уголок чистоты.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Используете ли вы экологически чистые чистящие средства?
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Да, мы заботимся об окружающей среде. В наших методах уборки используются экологически чистые продукты и методы, что позволяет сохранить ваше здоровье и планету.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Могу ли я запланировать регулярную уборку?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Конечно! Мы предлагаем гибкий график регулярной уборки - еженедельно, раз в две недели или ежемесячно. Для нас приоритетом является адаптация наших услуг к вашему образу жизни.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Сколько времени занимает типичная уборка?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Продолжительность зависит от размера вашего помещения и типа требуемой уборки. Наша команда работает эффективно и в то же время тщательно, не торопясь, прорабатывая каждое пятнышко.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box display="flex" flex-direction="row" flex-wrap="wrap">
					<Box
						display="flex"
						flex-direction="column"
						width="33%"
						padding="16px 16px 16px 16px"
						sm-width="100%"
					>
						<Box display="flex" flex-direction="column">
							<Text as="h3" font="--headline3" margin="12px 0">
								Ваши уборщики застрахованы и застрахованы?
							</Text>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Да, ваше душевное спокойствие превыше всего. Наши уборщики полностью застрахованы и связаны обязательствами, что обеспечивает вам спокойствие.
							</Text>
						</Box>
					</Box>
					<Box
						display="flex"
						flex-direction="column"
						width="33%"
						padding="16px 16px 16px 16px"
						sm-width="100%"
					>
						<Box display="flex" flex-direction="column">
							<Text as="h3" font="--headline3" margin="12px 0">
								Что отличает Povix от других клининговых служб?
							</Text>
						</Box>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Наша приверженность индивидуальным планам уборки, экологически чистые методы и профессиональная, но увлеченная команда отличают нас от других. Мы не просто убираем - мы заботимся о помещениях, которые обслуживаем, и о людях, которые в них живут.
						</Text>
					</Box>
					<Box
						display="flex"
						flex-direction="column"
						width="33%"
						padding="16px 16px 16px 16px"
						sm-width="100%"
					>
						<Box display="flex" flex-direction="column">
							<Text as="h3" font="--headline3" margin="12px 0">
								Как получить предложение по уборке?
							</Text>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Свяжитесь с нами, предоставив подробную информацию о вашем помещении и требованиях к уборке. Мы предоставим вам индивидуальное предложение, соответствующее вашим конкретным потребностям.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://povixvoom.com/img/11.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://povixvoom.com/img/12.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					У вас все еще есть вопросы? Мы готовы ответить на них по телефону или электронной почте. Наша команда готова помочь вам и направить вас по пути уборки с Povix.

				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});